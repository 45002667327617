<template>
  <div class="me" :class="{ clicable: isEditing }">
    <i v-if="iconClass" :class="iconClass"></i>
    <span class="hidden-xs">{{ $t(title) }}</span>
  </div>
</template>

<script>
export default {
  name: "DashboardPanelTitle",
  props: {
    panel: {
      type: Object,
      required: true
    },
    connector: {
      type: Object,
      required: false,
      default: () => null
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    dftPanels() {
      return this.$store.getters["dashboard/allPanels"] || [];
    },
    iconClass() {
      // it keeps backward compatibility
      // null = user has explicity removed the icon, so do not get it from the original panel
      // ""   = old screens = get the original panel icon, if there is one
      return (
        this?.panel?.icon === null ? "" : this?.panel?.icon ||
          (this.dftPanels.find(({ template }) => template.template == this.panel.template)
            ?.template?.icon ??
            "")
      );
    },
    allowedJSONContext() {
      return {
        system: this.$store.getters.systemProperties,
        connector: this.connector || {}
      };
    },
    title() {
      let res = this.panel.title && /[${}]/.test(this.panel.title)
        ? this.$utils.evaluate(this.allowedJSONContext, this.panel.title)
        : this.panel.title || "";
      return this.$utils.trim(res);
    }
  }
}
</script>

<style scoped>
.me {
  display: inline-block;
  white-space: nowrap;
  vertical-align: middle;
  padding: 0;
  font-size: 18px;
  color: #444;
}
.me > i {
  font-size: 90%;
  margin-right: 5px;
}

.clicable:hover {
  cursor: pointer;
  opacity: 0.8;
  color: #31708f;
}
</style>